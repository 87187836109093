<template>
  <v-dialog v-model="dialog" max-width="550">
    <v-card>
      <v-card-title>
        <h4>Add User</h4>
      </v-card-title>

      <v-card-text>
        <v-form ref="clientIdForm">
          <p class="dialog-text mb-5">
            Please enter correct client ID to get profile information.
          </p>
          <v-row>
            <v-col>
              <label>Client Id</label>
              <v-text-field
                label="Enter Client ID"
                outlined
                dense
                :rules="rules"
                single-line
                type="number"
                hide-details="auto"
                v-model="clientIdForm.clientId"
              ></v-text-field> </v-col
          ></v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="var(--primary)"
          :loading="loading"
          dark
          @click="getClientInfo"
          >Get Client Information
        </v-btn>
        <v-btn color="grey darken-1" dark @click="close"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['dialog'],
  data() {
    return {
      clientIdForm: {
        clientId: '',
      },
      rules: [(v) => !!v || 'Field is required '],
      loading: false,
    }
  },
  methods: {
    isEmpty(value) {
      if (value === null || value === '' || value === undefined) {
        return true
      } else {
        return false
      }
    },
    async getClientInfo() {
      if (this.$refs.clientIdForm.validate()) {
        this.loading = true
        try {
          let response = await this.$store.dispatch(
            'get_client_info',
            this.clientIdForm
          )
          if (this.isEmpty(response.data.clientInfo.clientName)) {
            this.$emit('clientEmailEmpty')
          } else {
            this.$emit('createuser')
            response.data.clientInfo.clientId = this.clientIdForm.clientId
            this.$store.commit('client_info', response.data.clientInfo)
            this.$root.snackbar.show({
              message: 'Client information retrieved successfully',
              color: 'success',
            })
          }
          this.close()
        } catch (error) {
          let errorMsg = error.response
            ? error.response.data.message[0]
            : 'An unexpected error occured'
          this.$root.snackbar.show({
            message: errorMsg,
            color: 'error',
          })
        } finally {
          this.loading = false
        }
      } else {
        this.$root.snackbar.show({
          message: 'Client ID is required.',
          color: 'error',
        })
      }
    },
    close() {
      this.$refs.clientIdForm.reset()
      this.$emit('update:dialog', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-text {
  margin-bottom: 0;
  font-size: 16px;
}
</style>